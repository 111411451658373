@import 'slippry.scss';

/* Set slider position on background (fixed menu overlay) */
.sy-box {
    position: sticky;
}

#menu {
    //background-color: lighten($text-color, 35%);
    background-color: #256F25;
    &:after, &:before {
        //background-color: lighten($text-color, 35%);
        background-color: #256F25;
    }
}

#nav-menu {
    top: 20px;
}

.logo-home {
    margin: 50px auto;
    margin-right: 40px;
    margin-left: 30px;
    margin-bottom: 3px;
}

.article-author {
    margin-top: 45px;
    margin-bottom: 45px;
}

.slogan h2 {
    color: #256F25;
}

.slogan p {
    color: #002D00;
}

@media screen and (max-width: 410px) {
    .slogan {
        display: none;
    }
}

.home-page-date-author-post {
    color: #666666;
    font-size: 0.875em;
}

/************ PARTNERS *************/
h2.partners {
    margin-bottom: 10px;
}

.rete-distretto {
    margin: 0;
}

.rete-distretto img {
    float: left;
    position:relative;
    margin: 0 15px 10px 0;
    width: 160px;
    height: auto;
}

.rete-distretto p {
    margin: 0;
    padding: 0;
}

.partner-istituzionali {
    margin-bottom: 40px;
}

.partner-istituzionali img {
    float: left;
    position:relative;
    margin: 10px;
    width: auto;
    height: 100px;
}

.altre-collaborazioni {
   margin-bottom: 40px;
}

.clear {
    clear: both;
}

.altre-collaborazioni img {
    float: left;
    position:relative;
    margin: 15px;
    width: auto;
    height: 50px;
}

/**** RESPONSIVE CALENDAR *****/
.responsiveCal {
    position: relative; padding-bottom: 75%; height: 0; overflow: hidden;
}

.responsiveCal iframe {
    position: absolute; top:0; left: 0; width: 100%; height: 100%;
}

.privacy-link a {
    color: #ffffff;
}

.fb-share-button {
    float: right;
}