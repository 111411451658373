/*
 * Emerald is a simple blog theme built for Jekyll.
 */

/*- Base reset -*/

* {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

html, body, h1, h2, h3, h4, h5, h6, p, ul, ol, li, img {
  margin: 0;
  padding: 0;
  border: 0;
}

/*- Base color -*/

$main-color: #016101;
$background-color: #FDFDFD;
$text-color: #222222;

/*- Base settings -*/

html {
	background-color: $background-color;
	font-size: 16px;
	@media (min-width: 940px) {
		font-size: 18px;
	}
	line-height: 1.5;
	color: $text-color;
}


/*- Link -*/
a {
	color: $main-color;
	text-decoration: none;
	font-weight: 700;
	&:hover,
	&:focus {
		color: darken($main-color, 5%);
	}
}